import {defineStore} from 'pinia'
import Vue from "vue";
import {validateResponse} from "@/utils";
import {createInvoice, deleteInvoice, getInvoices, markInvoiceAsPaid} from "@/services/invoice-service";
import _ from 'lodash'

export const useInvoiceStore = defineStore('invoice', {
    state: () => ({
        loading: false,
        invoices: [],
        currentInvoice: {}
    }),
    actions: {
        async createInvoice(values) {
            try {
                this.loading = true
                const response = await createInvoice(values)
                validateResponse(response, 201)
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async getInvoices(clientId) {
            try {
                this.loading = true
                const response = await getInvoices(clientId)
                const validatedResponse = validateResponse(response, 200)
                this.invoices = validatedResponse.rows
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async markInvoiceAsPaid(id) {
            try {
                this.loading = true
                const response = await markInvoiceAsPaid(id)
                validateResponse(response, 200)
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
        async deleteInvoice(id) {
            try {
                this.loading = true
                const response = await deleteInvoice(id)
                validateResponse(response, 204)
                Vue.$toast.success("Factura a fost stearsa cu success.");
                this.loading = false
            } catch (e) {
                this.loading = false
                Vue.$toast.error("Oops. A aparut o eroare!");
            }
        },
    }
})
