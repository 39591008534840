import {apiClient} from "./api-client.js";

export const createInvoice = (values) => apiClient({
    method: 'post',
    url: `/invoices`,
    data: values
});

export const getInvoices = (clientId) => apiClient({
    method: 'get',
    url: `/invoices?client=${clientId}`
});

export const markInvoiceAsPaid = (id) => apiClient({
    method: 'put',
    url: `/invoices/mark-as-paid/${id}`
});

export const deleteInvoice = (id) => apiClient({
    method: 'delete',
    url: `/invoices/${id}`
});
