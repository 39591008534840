<template>
  <div>
    <add-button style="float: right" @click="handleShowModal"/>
    <a-modal v-model:visible="showModal" title="Emite factura" @ok="handleAddInvoice" :destroyOnClose="true" :afterClose="reset" :confirm-loading="invoiceStore.loading">
      <a-row :gutter="16">
        <a-col :md="24">
          <a-select
              :value="createInvoiceValues.client || undefined"
              show-search
              :disabled="!!clientId"
              placeholder="Alege client"
              style="width: 100%; margin-bottom: 20px;"
              :options="clientList"
              :filter-option="filterOption"
              @change="handleClientSelectChange"
          ></a-select>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :md="12" :xs="24">
          <a-input v-model:value="createInvoiceValues.invoiceSeries" addon-before="Serie Factura" class="input-left">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-asterisk" viewBox="0 0 16 16">
                <path d="M8 0a1 1 0 0 1 1 1v5.268l4.562-2.634a1 1 0 1 1 1 1.732L10 8l4.562 2.634a1 1 0 1 1-1 1.732L9 9.732V15a1 1 0 1 1-2 0V9.732l-4.562 2.634a1 1 0 1 1-1-1.732L6 8 1.438 5.366a1 1 0 0 1 1-1.732L7 6.268V1a1 1 0 0 1 1-1z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
        <a-col :md="12" :xs="24">
          <a-input v-model:value="createInvoiceValues.invoiceNumber" addon-before="Numar Factura" class="input-left">
            <template #prefix>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hash" viewBox="0 0 16 16">
                <path d="M8.39 12.648a1.32 1.32 0 0 0-.015.18c0 .305.21.508.5.508.266 0 .492-.172.555-.477l.554-2.703h1.204c.421 0 .617-.234.617-.547 0-.312-.188-.53-.617-.53h-.985l.516-2.524h1.265c.43 0 .618-.227.618-.547 0-.313-.188-.524-.618-.524h-1.046l.476-2.304a1.06 1.06 0 0 0 .016-.164.51.51 0 0 0-.516-.516.54.54 0 0 0-.539.43l-.523 2.554H7.617l.477-2.304c.008-.04.015-.118.015-.164a.512.512 0 0 0-.523-.516.539.539 0 0 0-.531.43L6.53 5.484H5.414c-.43 0-.617.22-.617.532 0 .312.187.539.617.539h.906l-.515 2.523H4.609c-.421 0-.609.219-.609.531 0 .313.188.547.61.547h.976l-.516 2.492c-.008.04-.015.125-.015.18 0 .305.21.508.5.508.265 0 .492-.172.554-.477l.555-2.703h2.242l-.515 2.492zm-1-6.109h2.266l-.515 2.563H6.859l.532-2.563z"/>
              </svg>
            </template>
          </a-input>
        </a-col>
      </a-row>
      <a-row :gutter="16" style="margin-top: 20px;">
        <a-col :span="24">
          <a-range-picker :value="[createInvoiceValues.startDate, createInvoiceValues.endDate]" style="width: 100%" format="DD/MM/YYYY" @change="handleDateChange"/>
        </a-col>
      </a-row>
      <div v-if="errors.length" style="margin-top: 20px">
        <a-alert v-for="error in errors" :message="error" type="error" show-icon style="margin-bottom: 5px;"/>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {useClientStore} from "@/stores/client-store";
import AddButton from "@/components/Buttons/AddButton";
import _ from "lodash";
import moment from 'moment'
import {useInvoiceStore} from "@/stores/invoice-store";

export default {
  name: 'AddInvoiceModal',
  components: {
    AddButton
  },
  props: {
    clientId: String
  },
  setup () {
    const clientStore = useClientStore()
    const invoiceStore = useInvoiceStore()

    return {
      clientStore,
      invoiceStore
    }
  },
  data () {
    return {
      showModal: false,
      errors: [],
      clientList: [],
      createInvoiceValues: {
        client: this.clientId || null,
        invoiceSeries: this.client,
        invoiceNumber: null,
        startDate: moment.utc().subtract(1, 'months').startOf('month'),
        endDate: moment.utc().subtract(1, 'months').endOf('month')
      },
      names: {
        client: 'Client',
        invoiceSeries: 'Serie factura',
        invoiceNumber: 'Numar factura',
      }
    }
  },
  methods: {
    async handleAddInvoice () {
      this.checkForm()
      if (!this.errors.length) {
        await this.invoiceStore.createInvoice({
          client: this.createInvoiceValues.client,
          invoiceSeries: this.createInvoiceValues.invoiceSeries,
          invoiceNumber: this.createInvoiceValues.invoiceNumber,
          startDate: this.createInvoiceValues.startDate.toDate(),
          endDate: this.createInvoiceValues.endDate.toDate(),
        })
        this.showModal = false
        await this.invoiceStore.getInvoices(this.clientId)
      }
    },
    filterOption (input, option) {
      const client = _.find(this.clientList, (client) => {
        return option.data.props.value === client.value
      })
      return client.label.toLowerCase().includes(input.toLowerCase())
    },
    handleClientSelectChange (value) {
      this.createInvoiceValues.client = value
    },
    handleShowModal () {
      this.showModal = true
      console.log('open')
    },
    handleDateChange (dates) {
      this.createInvoiceValues.startDate = dates[0]
      this.createInvoiceValues.endDate = dates[1]
    },
    getCurrentInvoiceSeries () {
      const currentClient = _.find(this.clientStore.clients, (client) => client.id === this.clientId)
      this.createInvoiceValues.invoiceSeries = currentClient.invoiceSeries
    },
    reset() {
      this.errors = []
      this.createInvoiceValues = {
        client: this.clientId || null,
        invoiceSeries: null,
        invoiceNumber: null,
        startDate: moment.utc().subtract(1, 'months').startOf('month'),
        endDate: moment.utc().subtract(1, 'months').endOf('month')
      }
      this.getCurrentInvoiceSeries()
    },
    checkForm () {
      this.errors = []
      Object.keys(this.names).forEach(key => {
        if (!this.createInvoiceValues[key]) {
          this.errors.push(`Campul '${this.names[key]}' nu poate fi gol.`)
        }
      })
    }
  },
  async mounted() {
    await this.clientStore.getClients()
    this.getCurrentInvoiceSeries()
    this.clientList = this.clientStore.clients.map(client => ({value: client.id, label: client.name}))
  }
}
</script>

<style scoped>

</style>
