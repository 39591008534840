<template>
 <div>
   <a-row style="margin-top: 40px;">
     <a-col :span="12"><h4>Facturi</h4></a-col>
     <a-col :span="12"><add-invoice-modal :client-id="clientId"/></a-col>
   </a-row>
   <hr>
   <a-table
       :row-key="(record) => record.id"
       :columns="columns"
       :data-source="invoiceStore.invoices"
       :pagination="false"
       :scroll="{x: true}"
   >
     <template slot="id" slot-scope="text, record">
       <a-button type="link" @click="handleSelectInvoice(record.id)">{{text}}</a-button>
     </template>
     <template slot="createdAt" slot-scope="text, record">
       {{moment(record.createdAt).format('DD/MM/YYYY')}}
     </template>
     <template slot="paid" slot-scope="text, record">
       <svg v-if="record.paid" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#006700" class="bi bi-check2-circle" viewBox="0 0 16 16">
         <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/>
         <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/>
       </svg>
       <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#eca870" class="bi bi-x-circle" viewBox="0 0 16 16">
         <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
         <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
       </svg>
     </template>
     <template slot="actions" slot-scope="text, record">
       <a-dropdown placement="topLeft" :trigger="['click']">
         <a class="ant-dropdown-link" @click.prevent>
           Actiuni
           <DownOutlined />
         </a>
         <template #overlay>
           <a-menu>
             <a-menu-item style="padding:20px">
               <a :href="record.fileUrl" target="_blank" rel="noopener">
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                   <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                   <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                 </svg>
                 Descarca factura
               </a>
             </a-menu-item>
             <a-menu-item style="padding:20px">
               <a @click="markAsPaid(record)">
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
                   <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
                 </svg>
                 Marcheaza ca fiind achitat
               </a>
             </a-menu-item>
             <a-menu-item style="padding:20px">
               <a @click="showDeleteConfirmationModal">
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                   <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z"/>
                 </svg>
                 Sterge factura
               </a>
               <a-modal v-model:visible="showDeleteModal" title="Sterge factura" @ok="handleDeleteInvoice(record.id)">
                 Esti sigur ca vrei sa stergi factura <strong>{{record.id}}</strong>?
               </a-modal>
             </a-menu-item>
           </a-menu>
         </template>
       </a-dropdown>
     </template>
     <template slot="appointments" slot-scope="text, record">
       {{record.appointments.length}}
     </template>
   </a-table>
 </div>
</template>

<script>
import AddButton from "@/components/Buttons/AddButton";
import {useInvoiceStore} from "@/stores/invoice-store";
import moment from 'moment'
import AddInvoiceModal from "@/components/Invoices/AddInvoiceModal";

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: {
      customRender: "id"
    }
  },
  {
    title: 'Serie factura',
    dataIndex: 'invoiceSeries',
    key: 'invoiceSeries',
  },
  {
    title: 'Numar factura',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
  },
  {
    title: 'Data',
    dataIndex: 'createdAt',
    key: 'createdAt',
    scopedSlots: {
      customRender: "createdAt"
    }
  },
  {
    title: 'Numar sedinte',
    dataIndex: 'appointments',
    key: 'appointments',
    scopedSlots: {
      customRender: "appointments"
    }
  },
  {
    title: 'Valoare',
    dataIndex: 'price',
    key: 'price',
  },
  {
    title: 'Achitat',
    dataIndex: 'paid',
    key: 'paid',
    scopedSlots: {
      customRender: "paid"
    }
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    scopedSlots: {
      customRender: "actions"
    }
  },
];

export default {
  name: 'Invoices',
  components: {
    AddButton,
    AddInvoiceModal
  },
  props: ['clientId'],
  data () {
    return {
      showDeleteModal: false
    }
  },
  setup () {
    const invoiceStore = useInvoiceStore()

    return {
      invoiceStore,
      columns,
      moment
    }
  },
  methods: {
    handleSelectInvoice (invoiceId) {
      console.log(invoiceId)
    },
    async markAsPaid (invoice) {
      await this.invoiceStore.markInvoiceAsPaid(invoice.id, this.clientId)
      await this.invoiceStore.getInvoices(this.clientId)
    },
    async handleDeleteInvoice(invoiceId) {
      await this.invoiceStore.deleteInvoice(invoiceId)
      await this.invoiceStore.getInvoices(this.clientId)
      this.showDeleteModal = false
    },
    showDeleteConfirmationModal () {
      this.showDeleteModal = true
    },
  },
  async mounted () {
    await this.invoiceStore.getInvoices(this.clientId)
  }
}
</script>

<style scoped>

</style>
